import Link, { LinkProps } from "next/link";
import { PropsWithChildren } from "react";
import styled from "styled-components";

interface IButton {
  href?: LinkProps["href"];
  $black?: boolean;
  disabled?: boolean;
  className?: string;
}

export const Button = styled(
  ({ href, ...props }: PropsWithChildren<IButton>) => {
    return href ? (
      <Link href={href} passHref>
        <button {...props}>{props.children}</button>
      </Link>
    ) : (
      <button {...props}>{props.children}</button>
    );
  }
)`
  border: 1px solid #616161;
  font-family: "IBM Plex Mono", sans-serif;
  font-weight: 600;
  padding: 12px 22px;
  font-size: 16px;
  line-height: 16px;
  background: ${(props) => (props.$black ? "black" : "white")};
  color: ${(props) => (props.$black ? "white" : "black")};
  border-radius: 4px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  max-height: 42px;
`;
