import React from 'react';
import { Col } from "@layout/Col";
import { Row } from "@layout/Row";
import { Section } from "@secondary/Section";
import { BaseButton } from "@shared/button";
import styled from "styled-components";
import Container from '@mui/material/Container';
import Image from "next/image";
import Box from '@mui/material/Box';
import { SecondaryButton } from 'src/components/secondary-wallet/secondaryButton';
import Router from "next/router";
import { palette } from "@styles/theme";
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { getCloudinaryImage, isVideo } from '@utils/wallet/numberFormet.util';


interface IFeaturedArtist {
  item: any;
}
export const FeaturedArtistSection = styled(({ item, ...props }: IFeaturedArtist) => {

  return item ? (
    <Section {...props}>
      <Box className="featured-artist-block ">
        <Container>
          <Row className={item?.nft_view_alignment == false ? "contentformatleft" : ""}>
            <Col xs={12} sm={12} md={6} lg={6} className="order-flex contentArtist" >
              <Box>
                <h3 className="">Artist Spotlight</h3>
                <Typography component="div" variant="h1" className="featureArtistSubtitle" dangerouslySetInnerHTML={{ __html: item?.title ?? "" }} />

                <Box className="typo-body my-8 featureArtistSubtitle">
                  <Typography className="featureArtistSubtitle" dangerouslySetInnerHTML={{ __html: item?.description ?? "" }} />
                </Box>

                <SecondaryButton
                  onClick={() => { Router.push(item.button_redirecting_url) }}
                  className={"FAbtn"}
                  width="224px"
                >
                  Explore Artist
                </SecondaryButton>

              </Box>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              {
                isVideo(item?.url) ?

                  <CardMedia
                    component="video"
                    className="videoSize"
                    image={getCloudinaryImage(`https:${item?.url}`) + '#t=0.6'}
                    title="animated-url"
                    controls
                    muted
                  />
                  :

                  <Image src={getCloudinaryImage(`https:${item?.url}`)} width="100%" height="80%" layout="responsive" alt="" />
              }
            </Col>
          </Row>
        </Container>
      </Box>
    </Section>
  ) : null;
})`

  .secondary-btn {
    &:hover {
      background: linear-gradient(
          0deg,
          rgba(66, 0, 96, 0.4),
          rgba(66, 0, 96, 0.4)
        ),
        linear-gradient(301.04deg, #55ffbc -66.81%, #ba71db 90.61%);
    }
  }

  ${Row} {
    justify-content: end;
  }

  ${BaseButton} {
    max-width: 130px;
  }
  .contentformatleft {
    flex-direction: row-reverse!important;
  }
  .imageFile {
    height: 340px !important;
    position: relative;

    img {
      transition: transform 0.35s;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .featured-artist-block{
    h3{
      // font-family: 'MercuryDisplay';
      // font-style: normal;
      // font-weight: 325;
      // font-size: 36px;
      // line-height: 40px;
      // margin-bottom: 45px;
      // color: ${palette.lightBlack};
    }
    .MuiContainer-root .typo-body p{
      font-family: 'BentonSans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.1px;
    color: ${palette.lightBlack};
    padding-bottom: 15px;
    }
    .card-list-block{
      background-color: ${palette.lightBlack};
      border-radius: 5px ;
      overflow: hidden;
      margin-left:50px;
      .imageFile{
        overflow: hidden;
        max-height:400px;
        height:auto;
        img{
          width:100%;
        }
      }
      .details button{
        width: 100%;
        background: transparent;
        color: white;
        border-color: rgba(255,255,255,0.3);
        height: 55px;
        max-height: 55px;
        box-sizing: border-box;

        &:hover {
          background: linear-gradient(
              0deg,
              rgba(66, 0, 96, 0.4),
              rgba(66, 0, 96, 0.4)
            ),
            linear-gradient(301.04deg, #55ffbc -66.81%, #ba71db 90.61%);
        }
      }
    }

  }


  @media screen and (max-width: 575px) {
    .featured-artist-block{
      .card-list-block{
        margin-left: 0;
        margin-top: 40px;
      }
    }
  }

  .headingHome{
    font-family: 'Mercury Display' !important;
    font-style: normal !important;
    font-weight: 325 !important;
    font-size: 36px !important;
    line-height: 75px !important;
    color: ${palette.black};
  }


  .priceDisplay {
    marginLeft: '10px'
  }

  @media screen and (max-width: 767px) {

  .order-flex{
    order: 2;
  }
}

.featureArtistSubtitle {
  font-family: 'BentonSans';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.1px;
  color:${palette.black}
}
.FAbtn {
  font-family: BentonSans;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.04em;
  text-align: center;
}
.contentArtist {
  display:flex;
  align-items: center;
}

`;

