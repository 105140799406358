import { EViewSize } from "@enums/viewSize.enum";
import styled from "styled-components";

export const Section = styled.div<{ $bgColor?: string }>`
  // padding: 80px 0;
  position: relative;
  max-width: 100%;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.$bgColor ?? "transparent"};
  }

  @media screen and (max-width: ${EViewSize.desktop}) {
    // padding: 60px 0;
  }

  @media screen and (max-width: ${EViewSize.tablet}) {
    // padding: 40px 0;
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    // padding: 25px 0;
  }
`;
