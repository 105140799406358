import type { RowProps } from "@interfaces";
import classNames from "classnames";
import React from "react";
import styled from "styled-components";

const DEVICE_SIZES = ["xl", "lg", "md", "sm", "xs"];

export const Row = styled(
  React.forwardRef<HTMLDivElement, RowProps>(function Row(
    { className, as: Component = "div", noGutters = false, ...props },
    ref
  ) {
    const classes: string[] = [];

    DEVICE_SIZES.forEach((brkPoint) => {
      const propValue = (props as any)[brkPoint];
      delete (props as any)[brkPoint];

      let cols;
      if (propValue != null && typeof propValue === "object") {
        ({ cols } = propValue);
      } else {
        cols = propValue;
      }

      const infix = brkPoint !== "xs" ? `-${brkPoint}` : "";

      if (cols != null) classes.push(`row-cols${infix}-${cols}`);
    });

    return (
      <Component
        ref={ref}
        {...props}
        className={classNames(
          className,
          "row",
          noGutters && "no-gutters",
          ...classes
        )}>
        {props.children}
      </Component>
    );
  })
)``;
