import { Section } from "@secondary/Section";
import { FeaturedArtistSection } from "@sections/dynamic/FeaturedArtist";
import { HighlightsSection } from "@sections/dynamic/Highlights";
import { UpcomingSales } from "@sections/dynamic/UpcomingSales";
import { PageHeader } from "@sections/dynamic/PageHeader";
import { getAuthors, getPageHeaders } from "@utils";
import { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import styled from "styled-components";
import { useProfile } from "@hooks";
import { palette } from "@styles/theme";
import { Box } from "@mui/material";
import { VideoSection } from "@sections/dynamic/VideoSection";
import Head from "next/head";

const DynamicHome = styled((props) => {
  const { profile } = useProfile();
  return (
    <div {...props}>
      <Head>
        <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
        <title>{"Sotheby's Metaverse"}</title>
        <meta
          name="description"
          content="Sotheby’s is the premier destination for curated digital artworks and collectibles. Discover top Artists. Browse upcoming sales. Buy and sell rare digital works."
        />
      </Head>
      <div className="homePage">
        <Section className="">
          {
            props.dymanicarrpages.map((item: any, index: number) => {
              return (
                <Box key={index}>
                  {item?.contentTitle == "bannerPage" ?
                    <>
                      {/* banner set */}
                      <PageHeader item={item} />
                    </>
                    :
                    item?.contentTitle == "upcommingSales" ?
                      <Box className="section-pd-62">
                        {/* UpcomingSales set */}
                        <UpcomingSales item={item} />
                      </Box>
                      : item?.contentTitle == "featuredArtist" ?
                        <Box className="section-pd-62">
                          {/* Artist spotlight set */}
                          <FeaturedArtistSection item={item} />
                        </Box>

                        : item?.contentTitle == "video" ?
                          <Box className="section-pd-62">
                            {/* Artist spotlight set */}
                            <VideoSection video={`https:${item?.url}`} data={item} />
                          </Box>
                          :
                          item?.contentTitle == "highlighted_Nfts" &&
                          <>
                            {/* Highlights set */}
                            <HighlightsSection item={item} profile={profile} />
                          </>
                  }
                </Box>
              )
            })
          }
        </Section>

      </div>
    </div>
  );
})`
  color: ${palette.white};
  .welcomeBlock{
    h3{
      font-family: 'MercuryDisplay';
      font-style: normal;
      font-weight: 325;
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 45px;
      color: ${palette.white};
    }
    .typo-subtitle{
      font-family: 'BentonSans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 22px;
    color: ${palette.white};
    }
    .typo-body{
      p{
        width: 65%;
        font-family: 'BentonSans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 22px;
      color: #ffffff;
    }
    .typo-body {
      p {
        width: 65%;
        font-family: "BentonSans";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.1px;
      }
    }
  }
  // responsive
  @media screen and (max-width: 1599px) {
  }
  @media screen and (max-width: 1439px) {
    .welcomeBlock {
      h3 {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 40px;
      }
    }
  }
  @media screen and (max-width: 1279px) {
    .welcomeBlock {
      h3 {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 30px;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .welcomeBlock {
      .typo-body {
        p {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .welcomeBlock {
      h3 {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 30px;
      }
    }
  }
`;

export async function getServerSideProps(
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<any>> {
  await getPageHeaders(context);
  await getAuthors(context);

  return { props: {} };
}

export default DynamicHome;