import Image from "next/image";
import styled from "styled-components";
import { Button } from "../Button";

export const SingleGridItem = styled(({ item, ...props }: any) => {

    let imageFile = item[0]?.image?.file?.url;
    if (item[0]?.image?.file?.url.charAt() == "/") {
      imageFile = 'https:' + imageFile;
    }
    return (
      <>
        <div {...props}>
          <div className="image">
            <Image
              src={
                imageFile
                // "https://dummyimage.com/340x340/fff/aaa"
              }
              alt=""
              layout="fill"
            />
          </div>
          <div className="details p-7 mt-2">
            <h5 className="title">{item[0]?.data?.artist_name}</h5>
            <div className="artist my-2">
              {item[0]?.data?.nft_collection_name}
            </div>
            <div className="year mb-7">{item[0]?.data?.year}</div>
            <div className="d-flex align-items-end mb-5 USD-text">
            {item[0]?.data?.crypto_native} {item[0]?.data?.crypto_native_id}
              <span className="eth-price">{item[0]?.data?.crypto_fiat} {item[0]?.data?.crypto_fiat_id}</span>
            </div>
            <Button>Buy now</Button>
          </div>
        </div>
      </>
    );
  }
)`
  cursor: pointer;
  color: white;
  background: #222222;
  .image {
    height: 340px;
    position: relative;

    img {
      transition: transform 0.35s;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .details {
    padding: 20px;
    .title {
      font-size: 25px;
    }
    .artist {
      font-size: 15px;
    }
    .year {
      font-size: 15px;
    }

    ${Button} {
      width: 100%;
      background: transparent;
      color: white;
      border-color: rgba(255, 255, 255, 0.3);
      height: 55px;
      max-height: 55px;
      box-sizing: border-box;
    }

    .eth-price {
      font-size: 12px;
      margin-bottom: 3px;
      margin-left: 10px;
      opacity: 0.5;
      font-weight: 600;
    }
  }
`;
