import { Box, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { ImageorVideo } from "@utils/imageorvideo.utils";
import Image from "next/image";
import { palette } from "@styles/theme";
import { CardMedia } from "@material-ui/core";
import { useDeviceResponsive } from "@hooks";
import { EViewSize } from "@enums";
import { getCloudinaryImage } from "@utils/wallet/numberFormet.util";
import { LAZY_LOAD_IMAGE_BLUR } from "@utils/lazyLoadImageBlur";

interface videoData {
  video?: any;
  data: {
    title?: string;
    text?: string;
    textCaption?: string;
    description?: string;
    page?: string;
  };
}

export const VideoSection = styled((props: videoData) => {
  const type = ImageorVideo(props?.video);
  const { isMobile, isIOS } = useDeviceResponsive();

  return (
    <Box {...props}>
      <Grid container className="videobackground">
        <Grid item lg={12} sm={12} sx={{ width: "100%" }}>
          {props?.data?.page == "artist" ? (
            <Box>
              <Typography
                variant="h2"
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html: props?.data?.title ?? "",
                }}></Typography>
              <Typography
                className="videoAuthor text-center"
                dangerouslySetInnerHTML={{
                  __html: props?.data?.text ?? "",
                }}></Typography>
              <Typography
                className="videoLocation text-center"
                dangerouslySetInnerHTML={{
                  __html: props?.data?.textCaption ?? "",
                }}></Typography>
            </Box>
          ) : props?.data?.page == "home" ? (
            <Box>
              <Typography
                variant="h3"
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html: props?.data?.title ?? "",
                }}></Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="h2"
                className="headerColor aboutus text-center"
                dangerouslySetInnerHTML={{
                  __html: props?.data?.title ?? "",
                }}></Typography>
              <Typography
                className="headerColor text-center"
                dangerouslySetInnerHTML={{
                  __html: props?.data?.description ?? "",
                }}></Typography>
            </Box>
          )}
        </Grid>
        <Grid item lg={12} sm={12}>
          {type == "video" ? (
            <Box className="imageatVideo">
              <CardMedia
                component="video"
                className="videoSize"
                image={getCloudinaryImage(props?.video) + "#t=0.6"}
                title="animated-url"
                controls
                muted
              />
            </Box>
          ) : (
            <Box className="imageatVideo">
              <Image
                alt=""
                layout="responsive"
                objectFit="cover"
                src={getCloudinaryImage(props?.video)}
                width="100%"
                height="100%"
                blurDataURL={LAZY_LOAD_IMAGE_BLUR}
                placeholder="blur"
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
})`
  .videoAuthor {
    font-family: BentonSans;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px !important;
    letter-spacing: 0.0025em !important;
    text-align: center;
  }

  .videoLocation {
    font-family: BentonSans;
    font-size: 16px;
    font-style: italic !important;
    font-weight: 350 !important;
    line-height: 19px !important;
    letter-spacing: 0.0025em !important;
    text-align: center;
  }

  .imageatVideo {
    max-width: 65%;
    max-height: 60%;
    margin: 0 auto;
  }

  .videobackground: {
    background-color: ${palette.lightWhite};
  }

  .headerColor {
    color: ${palette.black};
  }

  .aboutus {
    font-family: Mercury Text G1;
    font-style: normal;
    font-weight: 325;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: 0.0025em;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1400px) {
    .imageatVideo {
      max-width: 80%;
      max-height: 80%;
    }
  }
  @media screen and (max-width: ${EViewSize.tablet}) {
    .imageatVideo {
      max-width: 85%;
      max-height: 85%;
    }
  }
`;
