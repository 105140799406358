import { Section } from "@secondary/Section";
import { Slide, Slider } from "@secondary/Slider";
import styled from "styled-components";
import Image from "next/image";
import Box from '@mui/material/Box';
import CardMedia from "@mui/material/CardMedia";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Router from "next/router";
import { SecondaryButton } from "src/components/secondary-wallet/secondaryButton";
import Link from "next/link";
import { palette } from "@styles/theme";
import { ImageorVideo } from "@utils/imageorvideo.utils";
import { ImageCompressed } from "@hooks";
import { getCloudinaryImage } from "@utils/wallet/numberFormet.util";
import { LAZY_LOAD_IMAGE_BLUR } from "@utils/lazyLoadImageBlur";

export const PageHeader = styled((props) => {
  return (
    <Section {...props}>
      <div className="homePageSlider sliderBtmDots">
        <Slider loop autoplay>
          {props?.item?.getData?.map((dataText: any, idx: number) => {
            const contentView = dataText?.content_alignment?.toLowerCase();
            return (
              //slider image side banner

              <Slide
                key={idx}
                className={
                  contentView == "left"
                    ? "bannerContentLeftAlignment"
                    : contentView == "right"
                    ? "bannerContentRightAlignment"
                    : ""
                }>
                <Box sx={{ flexGrow: 1 }} className="sliderPageHeader">
                  <Grid container className="HeaderGridAlign">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      className="grid-100 order-flex ">
                      <Box className="artistBanner">
                        <Box className="artistTitle">
                          <Typography
                            variant="h1"
                            dangerouslySetInnerHTML={{
                              __html: dataText?.title ?? "",
                            }}></Typography>
                        </Box>
                        <Box className="">
                          <Typography
                            variant="body1"
                            className="home_theme_desc"
                            dangerouslySetInnerHTML={{
                              __html: dataText?.description ?? "",
                            }}></Typography>
                        </Box>

                        <Link href={dataText.button_redirecting_url} passHref>
                          <SecondaryButton
                            onClick={() =>
                              Router.push(dataText?.button_redirecting_url)
                            }
                            className={"btnSubmit"}
                            width="224px">
                            {dataText?.buttonTitle}
                          </SecondaryButton>
                        </Link>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      className="grid-100 ">
                      {/* based on ext show image or video */}
                      <Box sx={{ postion: "relative" }}>
                        {ImageorVideo(dataText?.url && dataText?.url) ==
                        "video" ? (
                          <CardMedia
                            component="video"
                            className="videoSize"
                            image={getCloudinaryImage(
                              `https:${props?.item?.url}`
                            )}
                            title="animated-url"
                            autoPlay
                            muted
                          />
                        ) : (
                          <Image
                            src={getCloudinaryImage(
                              `https:${ImageCompressed(dataText?.url ?? "")}`
                            )}
                            alt={""}
                            width={640}
                            height={342}
                            layout="responsive"
                            objectFit="cover"
                            blurDataURL={LAZY_LOAD_IMAGE_BLUR}
                            placeholder="blur"
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Slide>
            );
          })}
        </Slider>
      </div>
    </Section>
  );
})`
  .sliderContBlock {
    position: relative;
    display: flex;
    width: 100%;
    height: 500px;
  }
  .sliderContBlock::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${palette.sligt_gray};
  }
  .sliderContBlock {
    .slideContWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      max-width: 100%;
    }
    .typo-h2 {
      position: relative;
      z-index: 2;
      font-family: "MercuryDisplay";
      font-style: normal;
      font-weight: 325;
      font-size: 48px;
      line-height: 58px;
      color: ${palette.white};
      margin-top: 0;
    }
    .typo-subtitle {
      position: relative;
      z-index: 2;
      font-family: "BentonSans Bold";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin-top: 15px;
      /* identical to box height, or 157% */

      text-align: center;

      color: ${palette.white};
    }
    .typo-body {
      position: relative;
      z-index: 2;
      font-family: "BentonSans";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      /* or 147% */

      text-align: center;
      letter-spacing: -0.1px;

      color: ${palette.white};
    }
    button {
      position: relative;
      z-index: 2;
    }
  }
  .sliderContBlock .sliderContBlock .sliderContBlock .typo-h2,
  .sliderContBlock .typo-subtitle,
  .sliderContBlock .typo-body,
  .sliderContBlock button {
    position: relative;
    z-index: 2;
  }

  padding: 0;
  color: ${palette.white};

  .bannerContentLeftAlignment .sliderContBlock {
    // padding-left: 5% !important;
    // padding-right: 5% !important;
  }
  .bannerContentLeftAlignment .sliderContBlock .fjXvlb {
    justify-content: center !important;
    text-align: left;
    align-items: flex-start;
  }
  .bannerContentLeftAlignment .sliderContBlock .fjXvlb .typo-body {
    text-align: left;
  }

  .bannerContentRightAlignment .sliderContBlock {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .bannerContentRightAlignment .sliderContBlock .fjXvlb {
    justify-content: center !important;
    text-align: left;
    align-items: flex-end;
  }
  .bannerContentRightAlignment .sliderContBlock .fjXvlb .typo-body {
    text-align: right;
  }

  .artistBanner p {
    align-items: flex-start;
    text-align: left;
  }

  @media screen and (max-width: 1200px) {
    .sliderPageHeader {
      height: auto;
    }
    .grid-100 {
      width: 100% !important;
      max-width: 100% !important;
      flex-basis: 100%;
    }
    .order-flex {
      order: 2;
    }
    .artistBanner {
      padding: 16px 29px;
      justify-content: center !important;
      align-items: center !important;
    }
    .artistBanner p {
      text-align: center;
    }
  }

  .artistTitle {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .artistBanner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    padding: 32px 100px;
    gap: 32px;
  }

  .HeaderGridAlign {
    align-items: center;
    background-color: ${palette.lightWhite};
  }

  .homePageSlider .swiper {
    padding-bottom: 32px @media screen and (max-width: 991px) {
      padding-bottom: 10px;
    }
  }
  .homePageSlider .swiper-pagination {
    bottom: 0px;
  }

  .btnSubmit {
    font-family: "BentonSans" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    text-align: center !important;
    letter-spacing: 0.04em !important;

    color: #ffffff;
  }
`;
