import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Section } from "@secondary/Section";
import { SingleGridItem } from "@secondary/dynamic/SingleGridItem";
import { Slide, Slider } from "@secondary/Slider";
import styled from "styled-components";
import Container from "@mui/material/Container";
import {
  getAllHighlightsTokens,
  getSupportedCurrencies,
  IRegistryTokens,
  usegetSupportedNetworks,
} from "@utils/wallet/wallet";
import { useWeb3 } from "@utils/wallet/connectContext";
import { config } from "@constants";
import {
  isEmpty,
  getStatus,
  PDPURL,
  getBuyNowOrMakeOfferPrice,
} from "@utils/wallet/numberFormet.util";
import { IMojitoProfile } from "@interfaces";
import { SecondaryWalletItemCard } from "@layouts/profile/wallet/components/SecondaryWalletCard";
import { useRouter } from "next/router";
import { ViewAllCardContent } from "@sections/component/ViewAllContent";
import Box from "@mui/material/Box";
import { useDeviceResponsive, useOrganization } from "@hooks";
import { palette } from "@styles/theme";
import ListItemComponent from "@layouts/profile/wallet/listitem";
import {
  CheckBuyMakeOffer,
  getKYCCheckBtn,
  getWalletData,
} from "@utils/wallet/walletConstant";
import BuyNowOrMakeOffer from "src/components/secondary-wallet/buyNowOrMakeOffer/buyNowOrMakeOffer";
import isBrowser from "@utils/isBrowser";

interface IFeaturedArtist {
  item: any;
  profile: IMojitoProfile;
}
export const HighlightsSection = styled(
  ({ item, profile, ...props }: IFeaturedArtist) => {
    const router = useRouter();
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const { organization } = useOrganization();
    const { isMobile, isTablet } = useDeviceResponsive();
    const { connect, setConnect } = useWeb3();

    const [nftModalOpen, setNftModalOpen] = useState<boolean>(false);
    const [HighLightData, setHighlightData] = useState<IRegistryTokens>({
      data: [],
      totalCount: 0,
      user: null,
    });
    const [buttonLoader, setButtonLoader] = useState({
      open: false,
      data: 0,
    });
    const [APIcontrol, setApiControl] = useState({ registryStaus: true });
    const [buyOrMakeOfferModal, setBuyOrMakeOfferModal] =
      useState<boolean>(false);
    const [mode, setMode] = useState<string>("BuyNow");

    const { AllRegistryTokens, AllRegistryRefetch } = getAllHighlightsTokens(
      {
        orgID: profile?.userOrgs?.[0]?.organizationId,
        categoryID: item?.mojito_category_id,
        offset: 0,
        limit: 15,
        marketplaceID: config.SECONDARY_MARKETPLACE_ID,
        buyerAddress: connect?.account ?? "",
        column: "priority",
        type: "ASC",
      },
      APIcontrol?.registryStaus
    );
    const { NetworkList } = usegetSupportedNetworks({
      orgId: profile?.userOrgs[0]?.organizationId,
    });

    const { supportedCurrencies } = getSupportedCurrencies({
      orgId: profile?.userOrgs?.[0]?.organizationId,
      nftTokenId: HighLightData?.data[buttonLoader?.data]?.NFTTokenID,
    });

    useEffect(() => {
      if (AllRegistryTokens) {
        setHighlightData(AllRegistryTokens);
        setApiControl({ registryStaus: false });
      }
    }, [AllRegistryTokens]);

    const onClickItem = (index: number) => {
      setButtonLoader({
        open: true,
        data: index,
      });
      setNftModalOpen(true);
    };

    const onButtonLoader = () => {
      setButtonLoader({
        ...buttonLoader,
        open: false,
      });
    };

    const handleModelClose = () => {
      setNftModalOpen(false);
    };

    const handleOpenBuyOrMakeOfferModal = () => {
      setBuyOrMakeOfferModal(true);
    };

    const handleCloseBuyOrMakeOfferModal = () => {
      setBuyOrMakeOfferModal(false);
      onButtonLoader();
    };

    const onClickMakeOfferBuyNow = (index: number, type: string) => {
      setButtonLoader({
        open: true,
        data: index,
      });
      if (type === "buyNow") {
        setMode("BuyNow");
        setBuyOrMakeOfferModal(true);
      } else {
        setMode("makeOffer");
        setBuyOrMakeOfferModal(true);
      }
    };

    const checkValidation = (): Promise<any> => {
      return new Promise((resolve, reject) => {
        if (!isAuthenticated) {
          loginWithRedirect({
            appState: {
              returnTo: window.location.pathname,
            },
          });
          reject("error");
        } else if (isEmpty(connect?.account)) {
          setConnect(prev=>({
            ...prev,
            openMetaMask: true
          }));
          reject("error");
        }
        resolve("success");
      });
    };
    return (
      <div {...props}>
        <Section>
          {item?.mojito_category_id && HighLightData?.data?.length && (
            <Box className="Highlights-block home_theme_background section-pd-80">
              <Container>
                <h3 className="mb-12 ">Featured Works on Secondary Market</h3>
                <Box className="sliderBtmDots">
                  <Slider
                    autoplay
                    slidesPerView={isMobile ? 1 : isTablet ? 3 : 4}
                    slidesPerGroup={isMobile ? 1 : isTablet ? 3 : 4}
                    spaceBetween={50}>
                    {HighLightData?.data?.map((wallet, index) => {
                      return (
                        <Slide key={index}>
                          <SecondaryWalletItemCard
                            styleId="HighLight"
                            imageurl={wallet?.MetaData?.image ?? ""}
                            secondImage={
                              wallet?.MetaData?.openSeaImageURL ?? ""
                            }
                            animationURL={wallet.MetaData.animationURL}
                            mediaImage={wallet?.mediaSourceURL ?? ""}
                            mediaExtension={wallet?.mediaSourceExtension ?? ""}
                            detailsUrl={PDPURL(
                              wallet?.Registry?.ContractAddress,
                              wallet?.TokenID,
                              wallet?.TokenType,
                              wallet?.TokenOwnerAddress
                            )}
                            CardContent={
                              <ViewAllCardContent
                                NFTList={wallet}
                                status={getStatus(
                                  wallet?.Status,
                                  wallet?.Owners,
                                  connect?.account ?? "1"
                                )}
                                styleId="oneofone"
                              />
                            }
                            onClickItem={() => {
                              checkValidation().then(() => {
                                const status = getStatus(
                                  wallet?.Status,
                                  wallet?.Owners,
                                  connect?.account ?? "1"
                                );
                                if (
                                  ["List Item", "Edit Listing"].includes(status)
                                ) {
                                  if (
                                    getKYCCheckBtn(
                                      organization,
                                      status,
                                      wallet?.isOfferExist
                                    ) !== "Incomplete Profile"
                                  ) {
                                    onClickItem(index);
                                  } else {
                                    window.open(
                                      config.SOTHEBYS_DOMAIN +
                                        "/buy/register/personal-information?origin=sothebys-metaverse&redirect=" +
                                        (isBrowser &&
                                          encodeURIComponent(
                                            window.location.href
                                          ))
                                    );
                                  }
                                } else if (
                                  getKYCCheckBtn(
                                    organization,
                                    status,
                                    wallet?.isOfferExist
                                  ) === "View Offer"
                                ) {
                                  router.push(
                                    PDPURL(
                                      wallet?.Registry?.ContractAddress,
                                      wallet?.TokenID,
                                      wallet?.TokenType,
                                      wallet?.TokenOwnerAddress
                                    )
                                  );
                                } else if (status === "Make Offer") {
                                  setButtonLoader({
                                    open: true,
                                    data: index,
                                  });
                                  onClickMakeOfferBuyNow(index, "makeOffer");
                                } else if (status === "Buy Now") {
                                  onClickMakeOfferBuyNow(index, "buyNow");
                                } else {
                                  router.push(
                                    PDPURL(
                                      wallet?.Registry?.ContractAddress,
                                      wallet?.TokenID,
                                      wallet?.TokenType,
                                      wallet?.TokenOwnerAddress
                                    )
                                  );
                                }
                              });
                            }}
                            btnName={getKYCCheckBtn(
                              organization,
                              getStatus(
                                wallet?.Status,
                                wallet?.Owners,
                                connect?.account ?? "1"
                              ),
                              wallet?.isOfferExist
                            )}
                            buttonLoader={
                              buttonLoader.data === index
                                ? buttonLoader.open
                                : false
                            }
                            btnClass={
                              getStatus(
                                wallet?.Status,
                                wallet?.Owners,
                                connect?.account ?? "1"
                              ) === "Buy Now"
                                ? "primary"
                                : "secondary"
                            }
                            disable={CheckBuyMakeOffer(
                              getStatus(
                                wallet?.Status,
                                wallet?.Owners,
                                connect?.account ?? "1"
                              ),
                              wallet?.isMakeOfferEnabled,
                              wallet?.isBuyNowEnabled
                            )}
                          />
                        </Slide>
                      );
                    })}
                  </Slider>
                </Box>
              </Container>
            </Box>
          )}
        </Section>
        {supportedCurrencies && connect?.connected && (
          <ListItemComponent
            organization={organization}
            nftModalOpen={nftModalOpen}
            profile={profile}
            address={connect?.account as `0x${string}`}
            handleModelClose={handleModelClose}
            refetchWalletContent={AllRegistryRefetch}
            buttonLoader={onButtonLoader} //
            WalletData={
              HighLightData?.data
                ? {
                    ...getWalletData(
                      HighLightData?.data[buttonLoader?.data],
                      String(connect?.account)
                    ),
                  }
                : null
            }
            supportedCurrencies={supportedCurrencies}
            NetworkList={NetworkList}
          />
        )}
        {supportedCurrencies && NetworkList && connect?.connected && (
          <BuyNowOrMakeOffer
            mode={mode}
            NFTDetailsState={{
              contractAddress:
                HighLightData?.data[buttonLoader?.data]?.Registry
                  ?.ContractAddress,
              latestOffer: HighLightData?.data[buttonLoader?.data]?.latestOffer,
              orderId:
                HighLightData?.data[buttonLoader?.data]?.listedOrderInfo?.id,
              network:
                HighLightData?.data[buttonLoader?.data]?.Registry?.Network
                  ?.name,
              nftTokenId: HighLightData?.data[buttonLoader?.data]?.NFTTokenID,
              price:
                getBuyNowOrMakeOfferPrice(
                  HighLightData?.data[buttonLoader?.data]?.Status,
                  HighLightData?.data[buttonLoader?.data]?.Price,
                  HighLightData?.data[buttonLoader?.data]?.listedOrderInfo
                    ?.price,
                  HighLightData?.data[buttonLoader?.data]?.Owners,
                  String(connect?.account)
                ) ?? [],
              listedCurrency:
                HighLightData?.data[buttonLoader?.data]?.listedOrderInfo
                  ?.listedCurrency?.contractAddress,
              tokenId: String(HighLightData?.data[buttonLoader?.data]?.TokenID),
              metadata: {
                image: HighLightData?.data[buttonLoader?.data]?.MetaData?.image,
                name: HighLightData?.data[buttonLoader?.data]?.MetaData?.name,
                artistName:
                  HighLightData?.data[buttonLoader?.data]?.Registry?.Artist
                    ?.artistName,
                animationURL:
                  HighLightData?.data[buttonLoader?.data]?.MetaData
                    ?.animationURL,
                year: HighLightData?.data[buttonLoader?.data]?.MintedAt,
              },
              tokenURI: HighLightData?.data[buttonLoader?.data]?.TokenURI,
              tokenType: HighLightData?.data[buttonLoader?.data]?.TokenType,
              ownerAddress:
                HighLightData?.data[buttonLoader?.data]?.TokenOwnerAddress,
              mediaExtension:
                HighLightData?.data[buttonLoader?.data]?.mediaSourceExtension,
              mediaImage:
                HighLightData?.data[buttonLoader?.data]?.mediaSourceURL,
            }}
            handleCloseModal={handleCloseBuyOrMakeOfferModal}
            handleOpenModal={handleOpenBuyOrMakeOfferModal}
            modalOpen={buyOrMakeOfferModal}
            onRefectchData={AllRegistryRefetch}
            orgId={profile?.userOrgs[0]?.organizationId}
            supportedCurrencies={supportedCurrencies}
            nftNetworkDetails={
              NetworkList?.filter(
                (ele) =>
                  ele?.id === HighLightData?.data[buttonLoader?.data]?.networkID
              )[0]
            }
          />
        )}
      </div>
    );
  }
)`
  ${SingleGridItem} {
    width: 100%;
    margin-bottom: 50px;
  }

  .pagination-highlight {
    color: ${palette.black};
    text-align: center;
    align-item: center;
    justify-content: center;
  }

  .Highlights-block {
    h3 {
      font-family: "Mercury Display" !important;
      font-style: normal !important;
      font-weight: 325 !important;
      font-size: 36px !important;
      line-height: 75px;
      margin-bottom: 45px;
      color: ${palette.black};
    }
    .swiper {
      .swiper-wrapper {
        margin-bottom: 50px;
      }
      .swiper-pagination {
        bottom: 0px;
      }
    }
    .imag-card {
      height: 370px !important;
      overflow: hidden;
      img {
        transition: transform 0.35s;
      }
      &:hover img {
        transform: scale(1.1);
      }
    }
  }

  @media screen and (max-width: 1439px) {
    .Highlights-block {
      h3 {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 40px;
      }
    }
  }

  @media screen and (max-width: 1279px) {
    .Highlights-block {
      h3 {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .Highlights-block {
      h3 {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 30px;
      }
    }
  }
`;
